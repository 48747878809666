import dayjs from 'dayjs'

export enum SubscriptionTypes {
  WORKOUTS = 'workouts',
}
export const showManagePaywall = (
  entitlements: PurchasesEntitlementInfos,
  type: SubscriptionTypes,
) => {
  const prettySubscription = getPrettySubscription(entitlements, type)
  if (
    prettySubscription?.includes('Cancelled') ||
    prettySubscription === 'Unsubscribed'
  ) {
    return true
  }

  return false
}
export const getShowPaymentForm = (
  entitlements: PurchasesEntitlementInfos | null | undefined,
  type: SubscriptionTypes,
) => {
  const entitlement = entitlements?.all?.[type]
  return !entitlement?.isActive
}
export const getPrettySubscription = (
  entitlements: PurchasesEntitlementInfos | null | undefined,
  type: SubscriptionTypes,
) => {
  const entitlement = entitlements?.all?.[type]
  let res:
    | 'Yearly'
    | 'Quarterly'
    | 'Monthly'
    | 'Yearly(Cancelled)'
    | 'Quarterly(Cancelled)'
    | 'Monthly(Cancelled)'
    | 'Unsubscribed'
    | 'Cancelled' = 'Unsubscribed'

  if (entitlement) {
    let packageDuration = entitlement.productIdentifier.includes('annual')
      ? 'Yearly'
      : entitlement.productIdentifier.includes('quarterly')
      ? 'Quarterly'
      : 'Monthly'

    if (entitlement?.store === 'STRIPE') {
      const daysDiff = dayjs(entitlement.expirationDate).diff(
        dayjs(entitlement?.latestPurchaseDate),
        'days',
      )
      if (daysDiff >= 360) {
        packageDuration = 'Yearly'
      } else if (daysDiff >= 90) {
        packageDuration = 'Quarterly'
      } else {
        packageDuration = 'Monthly'
      }
    }

    if (entitlement.isActive) {
      res = packageDuration
      if (!entitlement.willRenew) {
        res = `${packageDuration}(Cancelled)` as
          | 'Yearly(Cancelled)'
          | 'Monthly(Cancelled)'
      }
    } else {
      res = 'Cancelled'
    }

    if (entitlement?.store === 'STRIPE') {
      res += ' - Web'
    }
    if (entitlement?.store !== 'STRIPE') {
      res += ' - Mobile'
    }
  }
  return res
}

export enum SubscriptionTypes {
  WORKOUTS = 'workouts',
}
export interface PurchasesEntitlementInfo {
  /**
   * The entitlement identifier configured in the RevenueCat dashboard
   */
  readonly identifier: string
  /**
   * True if the user has access to this entitlement
   */
  readonly isActive: boolean
  /**
   * True if the underlying subscription is set to renew at the end of the billing period (expirationDate).
   */
  readonly willRenew: boolean
  /**
   * The last period type this entitlement was in. Either: NORMAL, INTRO, TRIAL.
   */
  readonly periodType: string
  /**
   * The latest purchase or renewal date for the entitlement.
   */
  readonly latestPurchaseDate: string
  /**
   * The first date this entitlement was purchased.
   */
  readonly originalPurchaseDate: string
  /**
   * The expiration date for the entitlement, can be `null` for lifetime access. If the `periodType` is `trial`,
   * this is the trial expiration date.
   */
  readonly expirationDate: string | null
  /**
   * The store where this entitlement was unlocked from.
   */
  readonly store:
    | 'PLAY_STORE'
    | 'APP_STORE'
    | 'STRIPE'
    | 'MAC_APP_STORE'
    | 'PROMOTIONAL'
    | 'AMAZON'
    | 'UNKNOWN_STORE'
  /**
   * The product identifier that unlocked this entitlement
   */
  readonly productIdentifier: string
  /**
   * False if this entitlement is unlocked via a production purchase
   */
  readonly isSandbox: boolean
  /**
   * The date an unsubscribe was detected. Can be `null`.
   *
   * @note: Entitlement may still be active even if user has unsubscribed. Check the `isActive` property.
   */
  readonly unsubscribeDetectedAt: string | null
  /**
   * The date a billing issue was detected. Can be `null` if there is no billing issue or an issue has been resolved
   *
   * @note: Entitlement may still be active even if there is a billing issue. Check the `isActive` property.
   */
  readonly billingIssueDetectedAt: string | null
}
/**
 * Contains all the entitlements associated to the user.
 */
export interface PurchasesEntitlementInfos {
  /**
   * Map of all EntitlementInfo (`PurchasesEntitlementInfo`) objects (active and inactive) keyed by entitlement identifier.
   */
  readonly all: {
    [key: string]: PurchasesEntitlementInfo
  }
  /**
   * Map of active EntitlementInfo (`PurchasesEntitlementInfo`) objects keyed by entitlement identifier.
   */
  readonly active: {
    [key: string]: PurchasesEntitlementInfo
  }
}
export interface PurchasesEntitlementInfos {
  /**
   * Map of all EntitlementInfo (`PurchasesEntitlementInfo`) objects (active and inactive) keyed by entitlement identifier.
   */
  readonly all: {
    [key: string]: PurchasesEntitlementInfo
  }
  /**
   * Map of active EntitlementInfo (`PurchasesEntitlementInfo`) objects keyed by entitlement identifier.
   */
  readonly active: {
    [key: string]: PurchasesEntitlementInfo
  }
}
