import dayjs from 'dayjs'
import React, { FC } from 'react'

import Button from './base/forms/Button'
import { ProductItem } from './ProductSelect'
import { Plan } from 'common/types/responses'
import InputGroup from './base/forms/InputGroup'
import ManageSubscription from './ManageSubscription'
import { useGetUserQuery } from 'common/services/useUser'
import { useGetPlansQuery } from 'common/services/usePlan'
import { useGetWebEntitlementsQuery } from 'common/services/useWebEntitlement'
import Container from './Container'
import { useRouter } from 'next/router'
import DownloadBtn from './DownloadAppBtn'

type AccountDetailType = {}

const AccountDetail: FC<AccountDetailType> = ({}) => {
  const { data: webEntitlements } = useGetWebEntitlementsQuery({})
  const { data: plans } = useGetPlansQuery({})
  const { data: user } = useGetUserQuery({})
  const isIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent)
  const isAndroidDevice = /Android/i.test(navigator.userAgent)

  const activePlan = plans?.find(
    (plan) => plan.id === webEntitlements?.all.workouts.priceId,
  ) as Plan

  const hasMobileSubscription = webEntitlements?.all.workouts.store !== 'STRIPE'

  return (
    <Container>
      <h3 className='mt-8 mb-5-half'>My Account</h3>
      <span className='text-small text-bold d-inline-flex mb-2'>
        Your Details
      </span>
      <InputGroup
        disabled
        placeholder='First Name'
        className='mb-4'
        name='first_name'
        icon={<span className='fa fa-user' />}
        value={user?.first_name || ''}
      />
      <InputGroup
        disabled
        placeholder='Last Name'
        className='mb-4'
        name='last_name'
        icon={<span className='fa fa-user' />}
        value={user?.last_name || ''}
      />

      {activePlan && (
        <>
          <span className='text-small text-bold d-inline-flex mb-2'>
            Your Plan
          </span>
          <ProductItem
            product={activePlan}
            isActive={true}
            onClick={() => {}}
            infoText={
              <span className='text-small d-flex justify-content-end'>
                {hasMobileSubscription
                  ? `Mobile Subscription`
                  : `Next Payment: ${dayjs(
                      webEntitlements?.all.workouts.expirationDate,
                    ).format('DD/MM/YYYY')}`}
                <span className='fa fa-clock ms-2' />
              </span>
            }
          />
        </>
      )}
      {hasMobileSubscription && (
        <p className='d-inline-flex mt-4-half text-small'>
          You have a mobile subscription. Please review your subscription via
          the subscription preferences on your mobile device.
          <br />
          <br /> If you don’t have the BBB app installed, click here to download
          it from the app store
        </p>
      )}
      <div className='mt-5 d-flex flex-column gap-3'>
        {hasMobileSubscription ? (
          <div className='row gap-3 mt-5-half'>
            <div className='col'>
              <DownloadBtn store='google' />
            </div>
            <div className='col'>
              <DownloadBtn store='apple' />
            </div>
          </div>
        ) : (
          <ManageSubscription />
        )}
      </div>
    </Container>
  )
}

export default AccountDetail
