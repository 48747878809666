import React, { ChangeEventHandler, FC } from 'react'
import cn from 'classnames'

interface Checkbox {
  label?: string
  className?: string
  value?: boolean
  id: string
  onChange: ChangeEventHandler
}

const Checkbox: FC<Checkbox> = ({ className, id, label, onChange, value }) => {
  return (
    <>
      <div className={cn('form-check', className)}>
        <input
          onChange={onChange}
          className='form-check-input'
          type='checkbox'
          checked={value}
          id={id}
        />
        <label className='form-check-label' htmlFor='flexCheckDefault'>
          {label}
        </label>
      </div>
    </>
  )
}

Checkbox.displayName = 'Checkbox'
export default Checkbox
