import { Res, WebEntitlementsAPI } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { selectUser } from './useUser'
import { PurchasesEntitlementInfo } from 'common/getPrettySubscription'
export const webEntitlementService = service
  .enhanceEndpoints({ addTagTypes: ['WebEntitlement'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWebEntitlements: builder.query<
        Res['webEntitlements'],
        Req['getWebEntitlements']
      >({
        providesTags: [{ id: 'LIST', type: 'WebEntitlement' }],
        queryFn: async (args, _, _2, baseQuery) => {
          const user = selectUser()
          if (user?.id) {
            const ents: {
              data: {
                expires_date: string
                purchase_date: string
                product_identifier: string
                product_id: string
              }[]
            } = await baseQuery({
              method: 'GET',
              url: `subscription/user/entitlements`,
            })
            const res = await baseQuery({
              method: 'GET',
              url: `subscription/user/`,
            }).then((res: WebEntitlementsAPI) => {
              const entitlements: Record<
                string,
                Partial<PurchasesEntitlementInfo> & { priceId: string }
              > = {}
              if (res?.data?.length) {
                res.data?.map((value) => {
                  const entitlement = ents?.data?.find(
                    (v) => v.product_identifier === value.product_id,
                  )
                  if (entitlement) {
                    entitlements[entitlement.product_id] = {
                      expirationDate: value.expires_date,
                      isActive: new Date(value.expires_date) >= new Date(),
                      latestPurchaseDate: value.purchase_date,
                      priceId: value.price_id,
                      productIdentifier: value.product_id,
                      store: value.store.toUpperCase() as any,
                      willRenew:
                        !value?.unsubscribe_detected_at &&
                        !value?.unsubscribe_detected_at,
                    }
                  }
                })
              }
              return { data: { all: entitlements } }
            })
            return Promise.resolve(res)
          }

          return Promise.reject(new Error('User is not identified'))
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getWebEntitlements(
  store: any,
  data: Req['getWebEntitlements'],
  options?: Parameters<
    typeof webEntitlementService.endpoints.getWebEntitlements.initiate
  >[1],
) {
  store.dispatch(
    webEntitlementService.endpoints.getWebEntitlements.initiate(data, options),
  )
  return Promise.all(
    store.dispatch(webEntitlementService.util.getRunningQueriesThunk()),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetWebEntitlementsQuery,
  // END OF EXPORTS
} = webEntitlementService

/* Usage examples:
const { data, isLoading } = useGetWebEntitlementsQuery({ id: 2 }, {}) //get hook
const [createWebEntitlements, { isLoading, data, isSuccess }] = useCreateWebEntitlementsMutation() //create hook
webEntitlementService.endpoints.getWebEntitlements.select({id: 2})(store.getState()) //access data from any function
*/
