import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { getUser } from './useUser'
import { getStore } from 'common/store'

export const subscriptionTokenService = service
  .enhanceEndpoints({ addTagTypes: ['SubscriptionToken'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createSubscriptionToken: builder.mutation<
        Res['subscriptionToken'],
        Req['createSubscriptionToken']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'SubscriptionToken' }],
        query: (query: Req['createSubscriptionToken']) => ({
          body: query,
          method: 'POST',
          url: `subscription/`,
        }),
        transformResponse: async (e) => {
          await getUser(getStore(), {})
          return e
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createSubscriptionToken(
  store: any,
  data: Req['createSubscriptionToken'],
  options?: Parameters<
    typeof subscriptionTokenService.endpoints.createSubscriptionToken.initiate
  >[1],
) {
  return store.dispatch(
    subscriptionTokenService.endpoints.createSubscriptionToken.initiate(
      data,
      options,
    ),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateSubscriptionTokenMutation,
  // END OF EXPORTS
} = subscriptionTokenService

/* Usage examples:
const { data, isLoading } = useGetSubscriptionTokenQuery({ id: 2 }, {}) //get hook
const [createSubscriptionToken, { isLoading, data, isSuccess }] = useCreateSubscriptionTokenMutation() //create hook
subscriptionTokenService.endpoints.getSubscriptionToken.select({id: 2})(store.getState()) //access data from any function
*/
