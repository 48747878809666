import { FC } from 'react'

export type ContainerProps = {}
export const Container: FC<ContainerProps> = ({ children }) => {
  return (
    <div className='row g-0'>
      <div className='col-1' />
      <div className='col-10 col-lg-4 col-md-6'>{children}</div>
    </div>
  )
}

export default Container
