import { FC } from 'react'
import { Project } from 'common/project'

export type DownloadBtnProps = {
  store: 'google' | 'apple'
}

export const DownloadBtn: FC<DownloadBtnProps> = ({ store }) => {
  const isGoogle = store === 'google'
  return (
    <a
      href={isGoogle ? Project.googleURL : Project.appleURL}
      className='download-app-btn text-decoration-none d-flex justify-content-center align-items-center bg-blackLighter flex-row w-full'
    >
      <h4
        className={`m-0 fa fab ${
          isGoogle ? 'fa-google-play' : 'fa-app-store-ios'
        } me-2`}
      />
      <span className='d-flex flex-column text-xs'>
        {isGoogle ? 'Get it on' : 'Download on the'}
        <span>
          <span className='text-large'>
            {isGoogle ? 'Google Play' : 'Apple Store'}
          </span>
        </span>
      </span>
    </a>
  )
}

export default DownloadBtn
