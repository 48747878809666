import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const subscriptionConfirmService = service
  .enhanceEndpoints({ addTagTypes: ['SubscriptionConfirm'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createSubscriptionConfirm: builder.mutation<
        Res['subscriptionConfirm'],
        Req['createSubscriptionConfirm']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'SubscriptionConfirm' }],
        query: (query: Req['createSubscriptionConfirm']) => ({
          body: query,
          method: 'POST',
          url: `subscription/confirm`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createSubscriptionConfirm(
  store: any,
  data: Req['createSubscriptionConfirm'],
  options?: Parameters<
    typeof subscriptionConfirmService.endpoints.createSubscriptionConfirm.initiate
  >[1],
) {
  store.dispatch(
    subscriptionConfirmService.endpoints.createSubscriptionConfirm.initiate(
      data,
      options,
    ),
  )
  return Promise.all(
    store.dispatch(subscriptionConfirmService.util.getRunningQueriesThunk()),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateSubscriptionConfirmMutation,
  // END OF EXPORTS
} = subscriptionConfirmService

/* Usage examples:
const { data, isLoading } = useGetSubscriptionConfirmQuery({ id: 2 }, {}) //get hook
const [createSubscriptionConfirm, { isLoading, data, isSuccess }] = useCreateSubscriptionConfirmMutation() //create hook
subscriptionConfirmService.endpoints.getSubscriptionConfirm.select({id: 2})(store.getState()) //access data from any function
*/
