import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const webSubscriptionService = service
  .enhanceEndpoints({ addTagTypes: ['WebSubscription'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      deleteWebSubscription: builder.mutation<
        Res['webSubscription'],
        Req['deleteWebSubscription']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'WebSubscription' }],
        query: (query: Req['deleteWebSubscription']) => ({
          body: query,
          method: 'DELETE',
          url: `subscription/user`,
        }),
      }),
      updateWebSubscription: builder.mutation<
        Res['webSubscription'],
        Req['updateWebSubscription']
      >({
        invalidatesTags: (res) => [
          { id: 'LIST', type: 'WebSubscription' },
          { id: res?.id, type: 'WebSubscription' },
        ],
        query: (query: Req['updateWebSubscription']) => ({
          body: query,
          method: 'PUT',
          url: `subscription/user/`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function updateWebSubscription(
  store: any,
  data: Req['updateWebSubscription'],
  options?: Parameters<
    typeof webSubscriptionService.endpoints.updateWebSubscription.initiate
  >[1],
) {
  store.dispatch(
    webSubscriptionService.endpoints.updateWebSubscription.initiate(
      data,
      options,
    ),
  )
  return Promise.all(
    store.dispatch(webSubscriptionService.util.getRunningQueriesThunk()),
  )
}
export async function deleteWebSubscription(
  store: any,
  data: Req['deleteWebSubscription'],
  options?: Parameters<
    typeof webSubscriptionService.endpoints.deleteWebSubscription.initiate
  >[1],
) {
  store.dispatch(
    webSubscriptionService.endpoints.deleteWebSubscription.initiate(
      data,
      options,
    ),
  )
  return Promise.all(
    store.dispatch(webSubscriptionService.util.getRunningQueriesThunk()),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useDeleteWebSubscriptionMutation,
  useUpdateWebSubscriptionMutation,
  // END OF EXPORTS
} = webSubscriptionService

/* Usage examples:
const { data, isLoading } = useGetWebSubscriptionQuery({ id: 2 }, {}) //get hook
const [createWebSubscription, { isLoading, data, isSuccess }] = useCreateWebSubscriptionMutation() //create hook
webSubscriptionService.endpoints.getWebSubscription.select({id: 2})(store.getState()) //access data from any function
*/
