import { FC } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import Image from 'next/image'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'

type StripeFormProps = {
  onChange: (e: StripeCardElementChangeEvent) => void
}

const StripeForm: FC<StripeFormProps> = ({ onChange }) => {
  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,

    style: {
      base: {
        '::placeholder': {
          color: '#fff',
        },
        color: '#fff',
        fontSize: '15px',
        padding: '20px',
      },
    },
  }

  return (
    <div className='pt-2-half'>
      <div className='bg-blackLighter px-4 py-3 rounded-xl'>
        <CardElement onChange={onChange} options={CARD_ELEMENT_OPTIONS} />
      </div>
      <div className='d-flex justify-content-end mt-2-half'>
        <Image
          src={require('public/images/cards/mastercard.svg')}
          alt={'mastercard'}
          height={30}
        />

        <Image
          src={require('public/images/cards/visa.svg')}
          alt={'visa'}
          height={30}
          className={'ms-1 me-1'}
        />

        <Image
          src={require('public/images/cards/americanexpress.svg')}
          alt={'americanexpress'}
          height={30}
        />
      </div>
    </div>
  )
}

export default StripeForm
