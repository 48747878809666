import { FC, useEffect } from 'react'
import Button from './base/forms/Button'
import {
  useDeleteWebSubscriptionMutation,
  useUpdateWebSubscriptionMutation,
} from 'common/services/useWebSubscription' // we need this to make JSX compile

type ManageSubscriptionType = {}

const ManageSubscription: FC<ManageSubscriptionType> = ({}) => {
  const [getManageLink, { data: updateLink }] =
    useUpdateWebSubscriptionMutation({})
  const [getCancelLink, { data: cancelLink }] =
    useDeleteWebSubscriptionMutation({})

  useEffect(() => {
    getManageLink({})
    getCancelLink({})
  }, [])
  return (
    <>
      <Button
        disabled={!updateLink}
        className='w-100'
        onClick={() => {
          window.open(updateLink?.url, '_blank')
        }}
      >
        Manage Subscription
      </Button>
      <Button
        theme='secondary'
        className='w-100'
        onClick={() => {
          window.open(cancelLink?.url, '_blank')
        }}
        disabled={!updateLink}
      >
        Cancel Subscription
      </Button>
    </>
  )
}

export default ManageSubscription
