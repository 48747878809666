import { FC } from 'react'
import { useGetUserQuery } from 'common/services/useUser'
import { useHydrated } from 'common/services/useHydrated'
import Image from 'next/image'

type ProfileContainerType = {}

const ProfileContainer: FC<ProfileContainerType> = ({ children }) => {
  const { data } = useGetUserQuery({})
  const hydrated = useHydrated()
  if (!hydrated) return null
  return children
}

export default ProfileContainer
