import React from 'react'
import 'project/polyfill'
import Head from 'next/head'

import Loader from 'components/Loader'
import { getStore } from 'common/store'
import { getPlans } from 'common/services/usePlan'
import PageContainer from 'components/PageContainer'
import AccountDetail from 'components/AccountDetail'
import { useGetUserQuery } from 'common/services/useUser'
import SetupSubscription from 'components/SetupSubscription'
import { NextPageWithLayout } from '../types/nextPageWithLayout'
import { ServerSidePageProps } from '../types/serversidePageProps'
import { nextReduxWrapper } from 'components/util/nextReduxWrapper'
import useNotLoggedInRedirect from 'common/hooks/useNotLoggedInRedirect'
import { useGetWebEntitlementsQuery } from 'common/services/useWebEntitlement'
import {
  getShowPaymentForm,
  getPrettySubscription,
  SubscriptionTypes,
} from 'common/getPrettySubscription'
import ProfileContainer from 'components/ProfileContainer'

export type AccountPageType = {}
const AccountPage: NextPageWithLayout<AccountPageType> = () => {
  const { data: user } = useGetUserQuery({})

  useNotLoggedInRedirect()
  const { data, isLoading } = useGetWebEntitlementsQuery({})

  const showPaywall = getShowPaymentForm(data, SubscriptionTypes.WORKOUTS)
  const subscription = getPrettySubscription(data!, SubscriptionTypes.WORKOUTS)
  if (!user || isLoading)
    return (
      <div className='text-center'>
        <Loader />
      </div>
    )

  return (
    <>
      <main>
        <ProfileContainer>
          <PageContainer>
            {showPaywall ? <SetupSubscription /> : <AccountDetail />}
          </PageContainer>
        </ProfileContainer>
      </main>
    </>
  )
}

//serverside fetching
export const getServerSideProps = nextReduxWrapper.getServerSideProps(
  () => async (): Promise<ServerSidePageProps<AccountPageType>> => {
    await getPlans(getStore(), {})
    return {
      props: {},
    }
  },
)

export default AccountPage
